var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"content-header"},[_c('b-col',{staticClass:"content-header-left mb-2",attrs:{"cols":"12","md":"12"}},[_c('b-row',{staticClass:"breadcrumbs-top"},[_c('b-col',{attrs:{"cols":"12"}},[_c('h1',{staticClass:"custom-header-title"},[_vm._v(" Editing "+_vm._s(_vm.existArticle.name)+" ")]),_c('p',{staticClass:"log_info"},[_vm._v(" Created by "+_vm._s(_vm.existArticle.createdBy ? _vm.existArticle.createdBy.name : '')+" on "+_vm._s(_vm.dateFormatWithTime(_vm.existArticle.createdAt))+" "),_c('br'),_vm._v("Last updated on "+_vm._s(_vm.dateFormatWithTime(_vm.existArticle.updatedAt))+" ")])])],1)],1)],1),_c('b-alert',{attrs:{"variant":"primary","show":""}},[_c('div',{staticClass:"alert-body"},[_c('span',[_c('strong',[_vm._v("For Nirvana SG")]),_vm._v(" This is the control panel where administrators can edit the content for the customer-facing mobile app.")])])]),_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitForm($event)}}},[_c('validation-observer',{ref:"articleEditForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-card',{attrs:{"header-tag":"header"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"md-12"}},[_c('validation-provider',{attrs:{"name":"Category","vid":"category","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Category*","label-for":"h-article-category","label-cols-md":"2","state":(errors.length > 0 || _vm.categoryValidation) ? false : null}},[_c('v-select',{attrs:{"id":"h-article-category","label":"name","placeholder":"Choose from list","options":_vm.categoryOptions,"reduce":function (name) { return name._id; },"clearable":true},on:{"input":function($event){_vm.categoryValidation == true ? _vm.categoryValidation = false : null}},model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.categoryValidation)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.categoryError)+" ")]):_vm._e()],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"md-12"}},[_c('b-form-group',{attrs:{"label":"Title*","label-for":"h-article-title","label-cols-md":"2"}},[_c('validation-provider',{attrs:{"name":"Title","vid":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-article-title","placeholder":"","state":(errors.length > 0 || _vm.titleValidation) ? false : null,"name":"title"},on:{"input":function($event){_vm.titleValidation == true ? _vm.titleValidation = false : null}},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.titleValidation)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.titleError)+" ")]):_vm._e()]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"md-12"}},[_c('b-form-group',{attrs:{"label":"Content*","label-for":"h-article-title","label-cols-md":"2"}},[_c('quill-editor',{ref:"quillTextEditor",staticClass:"editor d-block",attrs:{"value":_vm.content,"options":_vm.editorOption},on:{"change":_vm.onEditorChange}}),(_vm.contentValidation)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.contentError)+" ")]):_vm._e()],1)],1),_c('b-col',{attrs:{"cols":"md-12"}},[_c('validation-provider',{attrs:{"name":"Status","vid":"status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Status*","label-for":"h-event-status","label-cols-md":"2","state":(errors.length > 0 || _vm.statusValidation) ? false : null}},[_c('v-select',{attrs:{"id":"h-event-status","label":"title","options":_vm.statusOptions,"reduce":function (title) { return title.code; },"clearable":false},on:{"input":function($event){_vm.statusValidation == true ? _vm.statusValidation = false : null}},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.statusValidation)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.statusError)+" ")]):_vm._e()],1)]}}],null,true)})],1)],1)],1),_c('b-nav',{staticClass:"wrap-border save-nav"},[_c('li',{staticClass:"nav-item ml-auto"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"button","variant":"flat-primary"},on:{"click":_vm.cancelEdit}},[_vm._v(" Cancel ")])],1),_c('li',{staticClass:"nav-item mr-1"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"submit","variant":"success","disabled":_vm.content != '' ? invalid : true}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"SaveIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Save Article")])],1)],1)])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }